'use strict'

const storageFacade = require('./plarformInit/utils/storageFacade')
const workerManagerFactory = require('./plarformInit/workerManager')
const constants = require('./plarformInit/utils/constants')
const workerMessagesService = require('./plarformInit/workerMessagesService')
const messageProxy = require('./plarformInit/utils/messageProxy')

module.exports = {
    storageFacade,
    constants,
    workerMessagesService,
    messageProxy,
    workerManagerFactory
}
