'use strict'

class FallbackStorage {
    setItem(key, value) {
        this[key] = String(value)
    }
    getItem(key) {
        return this[key] || null
    }
    removeItem(key) {
        delete this[key]
    }
    clear() {
        Object.keys(this)
            .forEach(key => this.removeItem(key))
    }
    toJSON() {
        return this.storage.toJSON()
    }
}

module.exports = FallbackStorage