'use strict'

function getMessageHolder() {
    const messageQueue = []
    let messageTargetCallback = null
    return {
        sendOrHoldMessage(message, ports) {
            if (!messageTargetCallback) {
                messageQueue.push({message, ports})
            } else {
                messageTargetCallback(message, ports)
            }
        },
        setMessageHandler(callback) {
            messageTargetCallback = callback
            while (messageQueue.length > 0) {
                const obj = messageQueue.shift()
                messageTargetCallback(obj.message, obj.ports)
            }
        }
    }
}

module.exports = {
    get: getMessageHolder
}
